import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import "./index.css";
import App from "./App";
import ReactPixel from 'react-facebook-pixel';
import {initGA} from "./utils/googleAnalytics";

// Initialize Facebook Pixel
const pixelId = '1275162180341084'; // Replace with your actual Pixel ID
ReactPixel.init(pixelId);
ReactPixel.pageView(); // This will track the first page view
initGA();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
