import React, { useState } from "react";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import {GoTriangleDown} from "react-icons/go";


const Shop = () => {
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [category, setCategory] = useState("ALL");
  const [filter, setFilter] = useState("ALL");
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };

  return (
    <div className="max-w-container mx-auto px-4">

      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 pt-5 gap-10 justify-center">

        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">

          <div className={"flex flex-row justify-between"}>
            {/*
            <div className="flex items-center gap-2 md:gap-6 mt-4 md:mt-0">

              <div className="flex items-center gap-2 text-[#767676] relative">
                <label className="block">Filter:</label>
                <select
                    onChange={(e) => setFilter(e.target.value)}
                    id="filter"
                    className="w-35 md:w-64  border-[1px] border-gray-200 py-1 pr-8 pl-1 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
                >
                  <option value="ALL">ALL</option>
                  <option value="PROMO">PROMO</option>
                </select>
                <span className="absolute text-sm right-3 top-2.5">
            <GoTriangleDown/>
          </span>
              </div>
            </div>
            */}


            <div className="flex items-center gap-2 md:gap-6 mt-4 md:mt-0">

              <div className="flex items-center gap-2 text-[#767676] relative">
                <label className="block">Afficher:</label>
                <select
                    onChange={(e) => setCategory(e.target.value)}
                    id="category"
                    className="w-38 md:w-64  border-[1px] border-gray-200 py-1 pr-8 pl-1 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
                >
                  <option value="ALL">Tous les Produits</option>
                  <option value="maison">Produits de Maison</option>
                    <option value="decoration">Décoration</option>
                  <option value="facilite">Produits Innovants</option>
                    <option value="electronique">Electroniques</option>

                </select>
                <span className="absolute text-sm right-3 top-2.5">
            <GoTriangleDown/>
          </span>
              </div>
            </div>



          </div>

          <Pagination itemsPerPage={itemsPerPage} category={category} filter={filter} />
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default Shop;
