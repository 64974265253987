import React from "react";
import Banner from "../../components/Banner/Banner";
import SliderItems from "../../components/home/SliderItems/SliderItems";
import Sale from "../../components/home/Sale/Sale";
import { bonnesAffaires , SplOfferData , bestSeller  } from "../../constants";

const Home = () => {
  return (
    <div className="w-full mx-auto">

      <Banner  />

      <div className="max-w-container mx-auto px-4 mt-5">
          {/* <Sale /> */}


          <SliderItems source={bonnesAffaires} title={"Les Bonnes Affaires"}/>
          <SliderItems source={SplOfferData} title={"Offres Spéciales"}/>
          <SliderItems source={bestSeller} title={"Les Meilleures Ventes"}/>


      </div>
    </div>
  );
};

export default Home;
