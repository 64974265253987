// src/pages/Payment.js
import React, {useRef, useState} from "react";
import emailjs from "emailjs-com";
import { ImCross } from "react-icons/im";
import ReactPixel from "react-facebook-pixel";
import { resetCart } from "../../redux/orebiSlice";
import {useDispatch} from "react-redux";


const Payment = ({ isOpen, onClose, products, total }) => {
    const form = useRef();
    const dispatch = useDispatch();
    const [showConfirmation, setShowConfirmation] = useState(false); // State for popup confirmation

    const deliveryCharge = 7;

    // Format products for message content, including delivery and total
    const formatProducts = () => {
        const productsList = products
            .map((item) => `-Id : ${item.productName} ${item._id} \n Quantité: ${item.quantity}  \n Prix: ${item.price} DT `)
            .join("\n \n ");

        const totalAmount = parseFloat(total) + deliveryCharge;

        return `${productsList}\n\nFrais de Livraison: ${deliveryCharge} DT\nTotal: ${totalAmount} DT`;
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        formData.append("message", formatProducts());

        emailjs
            .sendForm(
                "service_23y9u0j",
                "template_ony0lje",
                form.current,
                "QUgMQoybeEzoC_hwl"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    console.log("Message sent");

                    setShowConfirmation(true);

                    // Track the purchase event with Facebook Pixel after a successful email submission
                    ReactPixel.track("Purchase", {
                        currency: "DT",
                        value: total + deliveryCharge,
                        content_ids: products.map((item) => item._id), // Track product IDs
                        content_name: products.map((item) => item.name).join(", "), // Track product names
                        content_type: "product",
                        contents: products.map((item) => ({
                            id: item._id,
                            quantity: item.quantity,
                            price: item.price,
                        })),
                    });
                },
                (error) => {
                    console.log(error.text);
                }
            );

    };
    const closeConfirmation = () => {
        dispatch(resetCart()); // Dispatch the resetCart action to clear the cart
        setShowConfirmation(false); // Hide the confirmation popup
        onClose(); // Close modal after sending email
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white w-full max-w-lg mx-4 p-6 rounded-lg shadow-lg relative">
                <ImCross
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-500 cursor-pointer hover:text-red-600"
                />
                <h2 className="text-2xl font-bold text-primeColor text-center mb-4">
                    Commander Vos Produits
                </h2>

                <form ref={form} onSubmit={sendEmail} className="space-y-4">
                    <div>
                        <label className="block font-semibold">Nom et Prénom</label>
                        <input
                            type="text"
                            name="user_name"
                            className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-primeColor"
                            required
                        />
                    </div>
                    <div>
                        <label className="block font-semibold">Email</label>
                        <input
                            type="email"
                            name="user_email"
                            className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-primeColor"
                        />
                    </div>
                    <div>
                        <label className="block font-semibold">Numéro de Télephone</label>
                        <input
                            type="text"
                            name="user_phone"
                            className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-primeColor"
                            required
                            pattern="^\d{8}$" // Exactly 8 digits
                            title="Veuillez entrer exactement 8 chiffres."
                        />
                    </div>
                    <div>
                        <label className="block font-semibold">Adresse </label>
                        <input
                            type="text"
                            name="user_adress"
                            className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-primeColor"
                            required
                            placeholder={"exemple: Tunis, L'aouina, 10 rue de tunis"}
                        />
                    </div>
                    <div>
                        <label className="block font-semibold">Produits</label>
                        <textarea
                            name="message"
                            className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-primeColor"
                            defaultValue={formatProducts()} // Automatically populate with products list
                            readOnly
                            rows={formatProducts().split("\n").length} // Set the initial number of rows based on content
                            style={{
                                resize: "none", // Prevent manual resizing
                                minHeight: "50px", // Set a minimum height
                                maxHeight: "200px", // Set a maximum height
                            }}
                        />
                    </div>

                    <input
                        type="submit"
                        value="Passer la Commande"
                        className="w-full bg-primeColor text-white text-lg py-2 cursor-pointer hover:bg-black transition duration-300"
                    />
                </form>
            </div>
            {/* Confirmation Popup */}
            {showConfirmation && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <p className="text-center text-lg font-bold"> Merci pour votre Commande !</p>
                        <p className="text-center text-sm font-bold pt-2">
                            Nous avons reçu votre commande.
                            Notre équipe vous contactera bientôt pour la confirmation.
                        </p>
                        <button
                            onClick={closeConfirmation}
                            className="mt-4 px-4 py-1 bg-primeColor justify-self-center
                            flex text-white rounded hover:bg-black transition duration-300"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Payment;
