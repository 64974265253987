import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { navBarList } from "../../../constants";
import Flex from "../../designLayouts/Flex";

const Header = () => {
  const location = useLocation();

  return (
      <div className="w-full h-20 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200">
        <nav className="h-full px-4 max-w-container mx-auto relative">
          <Flex className="flex items-center justify-between h-full">
            {/* Logo */}
            <Link className="mt-6" to="/">
              <div className="text-2xl font-semibold pb-6">ShopyCloud</div>
            </Link>

            {/* Navigation List */}
            <div className="flex items-center">
              <motion.ul
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="flex flex-row items-center w-auto z-50 p-0 gap-2"
              >
                {navBarList.map(({ _id, title, link }) => (
                    <NavLink
                        key={_id}
                        className="font-normal hover:font-bold px-4 py-0.5 text-base text-[#767676] hover:underline underline-offset-4 decoration-1 hover:text-[#262626] border-r-2 border-gray-300 hoverEffect last:border-r-0"
                        to={link}
                        state={{ data: location.pathname.split("/")[1] }}
                    >
                      {title}
                    </NavLink>
                ))}
              </motion.ul>
            </div>
          </Flex>
        </nav>
      </div>
  );
};

export default Header;
