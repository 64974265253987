import React, { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { useNavigate } from "react-router-dom";
import { paginationItems } from "../../../constants";

const HeaderBottom = () => {
  const [show, setShow] = useState(false); // To control dropdown visibility
  const navigate = useNavigate();
  const searchRef = useRef(null); // Ref for the search box and dropdown container
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setShow(true); // Show dropdown when typing
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setShow(false); // Hide dropdown when clicking outside
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      const filtered = paginationItems.filter((item) =>
          item.productName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [searchQuery]);

  return (
      <div className="w-full bg-[#F5F5F3] relative">
        <div className="max-w-container mx-auto">
          <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-center w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
            <div></div>
            <div
                ref={searchRef} // Attach the ref here
                className="relative w-full lg:w-[600px] h-[50px] sm:mt-3 lg:mt-1 text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl"
            >
              <input
                  className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
                  type="text"
                  onChange={handleSearch}
                  onClick={() => setShow(true)}
                  value={searchQuery}
                  placeholder="Cherchez vos produits ici..."
              />
              <FaSearch className="w-5 h-5" />
              {show && searchQuery && (
                  <div
                      className={`w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
                  >
                    {filteredProducts.map((item) => (
                        <div
                            onClick={() => {
                              navigate(
                                  `/product/${item._id
                                      .toLowerCase()
                                      .split(" ")
                                      .join("")}`,
                                  {
                                    state: {
                                      item: item,
                                    },
                                  }
                              );
                              setSearchQuery("");
                              setShow(false); // Hide dropdown after selecting a product
                            }}
                            key={item._id}
                            className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3"
                        >
                          <img className="w-24" src={item.img} alt="productImg" />
                          <div className="flex flex-col gap-1">
                            <p className="font-semibold text-lg">
                              {item.productName}
                            </p>
                            <p className="text-sm">
                              Prix:{" "}
                              <span className="text-primeColor font-semibold">
                          {item.price}DT
                        </span>
                            </p>
                          </div>
                        </div>
                    ))}
                  </div>
              )}
            </div>
          </Flex>
        </div>
      </div>
  );
};

export default HeaderBottom;
