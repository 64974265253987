import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";

const SpecialCase = () => {
  const products = useSelector((state) => state.orebiReducer.products);

  // Calculate the total quantity of items in the cart
  const totalQuantity = products.reduce((acc, item) => acc + item.quantity, 0);

  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (totalQuantity > 0) {
      // Trigger highlight whenever the total quantity changes
      setIsHighlighted(true);

      // Remove highlight after 500ms
      const timeout = setTimeout(() => {
        setIsHighlighted(false);
      }, 500);

      // Cleanup timeout
      return () => clearTimeout(timeout);
    }
  }, [totalQuantity]); // Watch for changes in totalQuantity

  return (
      <div className="fixed top-52 right-1 z-20 flex flex-col gap-2">
        <Link to="/cart">
          <div
              className={`${
                  isHighlighted ? "bg-green-500" : "bg-white"
              } w-16 h-[70px] rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer relative transition-colors duration-300`}
          >
            <div className="flex justify-center items-center">
              <RiShoppingCart2Fill className="text-2xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200" />
              <RiShoppingCart2Fill className="text-2xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200" />
            </div>
            <p className="text-xs font-semibold font-titleFont">Panier</p>
            {totalQuantity > 0 && (
                <p className="absolute top-1 right-2 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
                  {totalQuantity}
                </p>
            )}
          </div>
        </Link>
      </div>
  );
};

export default SpecialCase;
