import React, { useRef, useState } from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { MdOutlineLabelImportant } from "react-icons/md";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import ReactPixel from "react-facebook-pixel";
import Slider from "react-slick";

const Product = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _id = props._id;

    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const idString = (_id) => {
        return String(_id).toLowerCase().split(" ").join("");
    };
    const rootId = idString(_id);

    const handleProductDetails = () => {
        navigate(`/product/${rootId}`, {
            state: {
                item: props,
            },
        });
    };

    const handleAddToCart = () => {
        dispatch(
            addToCart({
                _id: props._id,
                productName: props.productName,
                promo: props.promo,
                quantity: 1,
                image: props.img,
                badge: props.badge,
                price: props.price,
                colors: props.color,
                description: props.des,
            })
        );

        ReactPixel.track("AddToCart", {
            content_name: props.productName,
            content_category: props.category,
            content_ids: [props._id],
            content_type: "product",
            value: props.price,
            currency: "DT",
        });
    };

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    const renderThumbnails = () => {
        return (
            <div className="flex justify-center mt-3 gap-1">
                {props.images?.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className={`w-6 h-8 object-cover cursor-pointer  ${
                            currentSlide === index
                                ? "border-2 border-gray-500"
                                : "border-2 border-transparent"
                        }`}
                        onClick={() => sliderRef.current.slickGoTo(index)}
                    />
                ))}
            </div>
        );
    };

    return (
        <div className="w-full relative group">
            <div className="max-w-20 max-h-40 relative overflow-hidden">
                <Slider ref={sliderRef} {...settings}>
                    {props.images?.map((image, index) => (
                        <div key={index}>
                            <img
                                className="max-w-20 max-h-40 object-cover items-center justify-center w-full h-full"
                                src={image}
                                alt={`Product Image ${index + 1}`}
                            />
                        </div>
                    ))}
                </Slider>
                {/* Badges */}
                {props.badge && (
                    <div className="absolute top-6 left-8">
                        <Badge text="New" />
                    </div>
                )}
                {props.promo && (
                    <div className="absolute top-6 left-8">
                        <Badge text="Promo " />
                    </div>
                )}
                {/* Overlay Actions */}
                <div className="w-full h-22 absolute bg-[rgba(0,0,0,0.4)] -bottom-[130px] group-hover:bottom-0 duration-700">
                    <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
                        <li
                            onClick={handleAddToCart}
                            className="text-white hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 cursor-pointer pb-1 pt-1 duration-300 w-full"
                        >
                            Ajouter Au Panier
                            <span>
                <BsSuitHeartFill />
              </span>
                        </li>
                        <li
                            className="text-white hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 cursor-pointer pb-1 duration-300 w-full"
                            onClick={handleProductDetails}
                        >
                            Afficher Les Détails
                            <span>
                <MdOutlineLabelImportant />
              </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="max-w-80 pb-3 flex flex-col gap-1 border-[1px] border-t-0 px-4">
                {renderThumbnails()}
                <div className="flex items-center justify-between font-titleFont min-h-[50px]">
                    <h2 className="text-sm text-primeColor font-bold">
                        {props.productName}
                    </h2>
                </div>
                <div className="flex flex-row items-center justify-between">
                    <p className="text-[#767676] text-[14px]">{props.color}</p>
                    <div className="flex flex-row items-center">
                        {props.promo ? (
                            <>
                                <p className="text-[#767676] text-[14px] mr-2 line-through">
                                    {Math.round(props.price / (1 - props.promo / 100))}DT
                                </p>
                                <p className="text-red-500 text-[14px]">{props.price}DT</p>
                            </>
                        ) : (
                            <p className="text-[#767676] text-[14px]">{props.price}DT</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
