import React from "react";
import {
  FaFacebook,
  FaWhatsapp,
  FaInstagram,
} from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";


const Footer = () => {

  return (
      <div className="w-full bg-[#F5F5F3] py-10 mt-6">
        <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-0   px-4 gap-10">
          <div className="col-span-2  justify-self-center">
            <FooterListTitle title="Informations De Contact"/>
            <div className="flex flex-col gap-6">

              <ul className="flex items-center gap-2 justify-center">


                <a
                    href="https://www.facebook.com/ShopyCloud1/"
                    target="_blank"
                    rel="noreferrer"
                >
                  <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                    <FaFacebook/>
                  </li>
                </a>
                <a
                    href="https://www.instagram.com/shopy.cloud/"
                    target="_blank"
                    rel="noreferrer"
                >
                  <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                    <FaInstagram/>
                  </li>
                </a>
                <a
                    href="https://wa.me/+21653286684"
                    target="_blank"
                    rel="noreferrer"
                >
                  <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                    <FaWhatsapp/>
                  </li>
                </a>
              </ul>

            </div>
          </div>


        </div>
      </div>
  );
};

export default Footer;
